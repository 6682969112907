import {Component, HostBinding, input} from '@angular/core';
import {LoadingIconComponent} from '../loading-icon/loading-icon.component';


@Component({
  selector: '[app-loading]',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  imports: [
    LoadingIconComponent
  ]
})
export class LoadingComponent {

  readonly loading = input(false);

  readonly disabled = input<boolean | null>(false);

  readonly grey = input(true);

  readonly small = input(true);

  readonly success = input<boolean | null>(false);

  readonly text = input<string>();

  @HostBinding('class.disabled') get disabledClass() {
    return this.disabled() || this.loading();
  }

  @HostBinding('class.success') get successClass() {
    return this.success();
  }

}
